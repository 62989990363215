import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"
import ReactMarkdown from "react-markdown/with-html"

import SEO from "../components/seo"
import Layout from "../components/layout"

import { Button } from "../components/buttons/buttons"
import { Section, Container } from "../components/grid/grid"
import { PostHeader, PostContent } from "../components/post/post"
import { SocmedLinks } from "../components/socmed-links/socmed-links"
import { CoverFeatured } from "../components/cover-featured/cover-featured"

const Headings = ({ data }) => {
  if (data.attributes.level === 2) {
    return <h2 dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h2>
  } else if (data.attributes.level === 3) {
    return <h3 dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h3>
  } else if (data.attributes.level === 4) {
    return <h4 dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h4>
  }
  return <div></div>
}

const News = props => {
  const staticData = useStaticQuery(graphql`
    query {
      featured: file(relativePath: { eq: "img-featured-news-sample.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      allNewsJson {
        edges {
          node {
            newsItems {
              id
              category
              title
              excerpt
              date(formatString: "MMM DD, YYYY")
              url
              img {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const redirectAmpHtml = process.env.BASE_URL + props.location.pathname
  const newsItem = staticData.allNewsJson.edges[0].node.newsItems[1]
  const newsItems =
    props.pathContext.langKey === "ID"
      ? props.pathContext?.data?.node?.translation ||
        props.pathContext?.data.node ||
        props.pathContext?.data
      : props.pathContext?.data?.node
  const seo = newsItems?.seo

  const featuredImage =
    props.pathContext?.data?.node?.featuredImage?.mediaItemUrl

  const schema = {
    "@context" : "http://schema.org",
    "@type" : "Organization",
    "name" : "Sinarmasland",
    "url" : "https://www.sinarmasland.com",
    "logo" : {
      "@type" : "ImageObject",
      "url" : "https://www.sinarmasland.com/static/logo_sinarmas-light-eee193bd58f1076f40d39fb926ae8100.png",
      "width" : "307",
      "height" : "120"
    },
    "sameAs" : [
      "https://www.facebook.com/sinarmasland",
      "https://twitter.com/sinarmas_land",
      "https://www.instagram.com/sinarmas_land/",
      "https://www.youtube.com/channel/UCVxIPn2_4HYa18VclGzIqKg"
    ]
  }

  // useEffect(() => {
  //   // document.querySelectorAll('[rel="canonical"]')[0].href = redirectAmpHtml
  // }, [])


  return (
    <Layout 
      location={props.location} 
      isAmp={true}
      lang={props.pathContext.langKey}
      newsLangSlug={
        props?.pathContext?.translation?.node?.slug ||
        props?.pathContext?.translation?.slug ||
        props?.pathContext?.data?.slug ||
        newsItems.slug
      }
    >
      <Helmet>
        <body className="bd-post-details" />
        <html amp="" />
        <link rel="canonical" href={redirectAmpHtml} />
        <style amp-boilerplate="">
          {`
            body {
              -webkit-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              -moz-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              -ms-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              animation: -amp-start 8s steps(1, end) 0s 1 normal both;
            }
            @-webkit-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-moz-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-ms-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-o-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
          `}
        </style>
        <noscript>
          {`
            <style amp-boilerplate="">
              body {
                -webkit-animation: none;
                -moz-animation: none;
                -ms-animation: none;
                animation: none;
              }
            </style>
          `}
        </noscript>
        <style type="text/css" amp-custom="">
          {`
            /* cyrillic-ext */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 300;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8WAc5gU1EQVg.woff2) format('woff2');
              unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 300;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8WAc5pU1EQVg.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* vietnamese */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 300;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8WAc5iU1EQVg.woff2) format('woff2');
              unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
            }
            /* latin-ext */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 300;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8WAc5jU1EQVg.woff2) format('woff2');
              unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
            }
            /* latin */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 300;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8WAc5tU1E.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            /* cyrillic-ext */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe0qMImSLYBIv1o4X1M8ccewI9tScg.woff2) format('woff2');
              unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe0qMImSLYBIv1o4X1M8cce5I9tScg.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* vietnamese */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe0qMImSLYBIv1o4X1M8cceyI9tScg.woff2) format('woff2');
              unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
            }
            /* latin-ext */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe0qMImSLYBIv1o4X1M8ccezI9tScg.woff2) format('woff2');
              unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
            }
            /* latin */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe0qMImSLYBIv1o4X1M8cce9I9s.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            /* cyrillic-ext */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 700;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8GBs5gU1EQVg.woff2) format('woff2');
              unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 700;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8GBs5pU1EQVg.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* vietnamese */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 700;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8GBs5iU1EQVg.woff2) format('woff2');
              unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
            }
            /* latin-ext */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 700;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8GBs5jU1EQVg.woff2) format('woff2');
              unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
            }
            /* latin */
            @font-face {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 700;
              font-display: swap;
              src: url(https://fonts.gstatic.com/s/nunitosans/v11/pe03MImSLYBIv1o4X1M8cc8GBs5tU1E.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            body, footer {
              font-family: 'Nunito Sans', sans-serif;
            }
            .share-caption{
              color: #ab0204;
              margin-right: 1rem;
              vertical-align: middle;
              display: inline-block;
              padding-bottom: 14px;
            }
            amp-img.img-content img{
              object-fit: contain;
            }
            amp-social-share[type="system"] {
              background-image: none;
              color:#fff;  
              padding: 3px;
            }
            @media (max-width: 320.98px) {
              #navbarDesktop{
                display: none;
              }
              #navbarMobile{
                display: block;
              }
            }
            @media (min-width: 321px) and (max-width: 991.98px) {
              .text-center{
                text-align: center:
              }
              .img-ratio.img-ratio-1-1,
              .img-ratio.img-ratio-2-1,
              .img-ratio.img-ratio-3-2{
                width: 100%;
              }
              .mt-40{
                margin-top: 40px;
              }
              .m-0{
                margin: 0px;
              }
              .py-main {
                padding-top: 2.5rem;
                padding-bottom: 2.5rem;
              }
              .row {
                display: flex;
                flex-wrap: wrap;
              }
              .col-lg-2, .col-lg-4, .col-lg-6, .col-lg-10{
                position: relative;
                width: 100%;
                padding-right: 20px;
                padding-left: 20px;
              }
              .col-12.d-lg-none{
                display: block;
                width: 100%;
              }
              footer .col-12.col-lg-2 {
                width: 100%;
                position: relative;
                width: 100%;
                padding: 0px;
              }
              footer .col-6.col-lg-2 {
                flex: 0 0 50%;
                max-width: 50%;
                position: relative;
                width: 100%;
                padding: 0px;
              }
              footer .col-6.col-lg-2 .footer-col .d-none.d-lg-block{
                display:none;
              }
              #navbarDesktop{
                display: none;
              }
              #navbarMobile{
                display: block;
              }
              #navbarMobile header{
                position: fixed;
                width: 100%;
              }
              #navbarMobile .navbar-nav-main{
                height: 60px;
                max-width: 100%;
              }
              #navbarMobile .navbar-nav-main ul{
                padding: .5rem 1rem;
                max-width: 100%;
              }
              #navbarMobile .navbar-nav-main ul li.navbar-toggler, #navbarMobile .navbar-nav-main ul li.login-toggler{
                float: right;
              }
              #navbarMobile .navbar-nav-main ul li .btn-login{
                text-decoration: none;
                right: 50px;
                padding: 5px 15px;
                margin-top: 10px;
                color: #fff;
                background-color: #ab0204;
                border-color: #ab0204;
                display: inline;
                text-align: center;
                font-weight: 400;
                vertical-align: middle;
                margin-right: 5px;
              }
              #navbarMobile amp-sidebar{
                width: 100%;
                flex-direction: column;
                background-color: #fff;
                max-width: 100vw;
              }
              #navbarMobile amp-sidebar .close-sidebar-left{
                padding: 15px;
                text-align: left;
              }
              #navbarMobile amp-sidebar .close-sidebar-right{
                padding: 15px;
                text-align: right;
              }
              #navbarMobile amp-sidebar ul.sidebar{
                padding: 15px;
                list-style-type: none;
                display: flex;
                flex-direction: column;
              }
              #navbarMobile amp-sidebar ul.sidebar li form .form-control-search{
                position: relative;
                border: none;
                border-bottom: 1px solid #ccc;
                padding: 0.25rem 0;
                margin-bottom: 1rem;
                font-size: 2rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li ul.sub-about{
                list-style-type: none;
              }
              #navbarMobile amp-sidebar ul.sidebar li ul.sub-about li a{
                list-style-type: none;
                font-size: 1.25rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.lang-toggler{
                margin-top: 3rem;
                display: flex;
              }
              #navbarMobile amp-sidebar ul.sidebar li.lang-toggler .mr-2{
                margin-right: 0.5rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.lang-toggler .mr-2.lang-item.active{
                color: #ab0204;
                font-weight: 700;
                margin-right: 0.5rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.primary-link a{
                font-size: .8rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #ab0204;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li span{
                font-size: 2rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #ab0204;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.sub-menu-outline{
                border: 0.5px solid #e5e5e5;
                display: block;
                width: 100%;
                padding-left: 1rem;
                text-align: left;
                position: relative;
                max-width: 90%;
                margin-bottom: 1rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.sub-menu-outline a{
                font-size: 1.25rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li span{
                font-size: 2rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #ab0204;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li a, #navbarMobile amp-sidebar ul.sidebar li span, #navbarMobile amp-sidebar ul.sidebar li .sub-sidebar{
                font-size: 2rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #333;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.glide__slide a{
                font-size: 1rem;
              }
              footer{
                display: block:
              }
              footer .red-ribbon{
                display: flex;
                justify-content: flex-end;
                position: relative;
                margin-top: -16px;
                width: 100%;
              }
              footer .red-ribbon amp-img{
                height: 65px;
                z-index: 3;
              }
              footer .red-ribbon .ribbon {
                height: 49px;
                position: absolute;
                bottom: 0;
              }
              footer .red-ribbon .ribbon.red {
                z-index: 1;
                left: 0;
                background: #dd1b20;
                height: 39px;
                padding: 7px 0px;
                width: calc(100% - 182px);
              }
              footer .red-ribbon .ribbon.black {
                right: 0;
                z-index: 2;
                bottom: 0;
                width: 180px;
                background: #191919;
              }
              footer .footer-top {
                background-color: #191919;
                position: relative;
              }
              footer .footer-top hr {
                border-top: 1px solid hsla(0,0%,100%,.1);
                margin-bottom: 1.5rem;
              }
              footer .footer-top .container{
                max-width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                margin-right: auto;
                margin-left: auto;
              }
              footer .footer-top .container .footer-col.footer-address{
                padding-right: 2.5rem;
              }
              footer .footer-top .container .footer-col h5{
                color: #fff;
                font-size: 1.125rem;
                margin-bottom: 1rem;
              }
              footer .footer-top .container .footer-col.footer-address p{
                line-height: 1.8;
                font-size: .875rem;
                color: hsla(0,0%,100%,.75);
                margin-top: 0;
                margin-bottom: 1rem;
              }
              footer .footer-top .container .footer-col .footer-nav{
                list-style-type: none;
                padding: 0;
                margin-bottom: 0;
                margin-top: 0;
              }
              footer .footer-top .container .footer-col .footer-nav .footer-item:first-child .footer-link{
                margin-top: -0.5rem;
                display: inline-block;
                padding: 0.5rem 0;
                color: hsla(0,0%,100%,.5);
              }
              footer .footer-top .container .footer-col .footer-nav .footer-item .footer-link{
                text-decoration: none;
                display: inline-block;
                padding: 0.5rem 0;
                color: hsla(0,0%,100%,.5);
              }
              footer .footer-top .container .footer-col h5{
                color: #fff;
                font-size: 1.125rem;
                margin-bottom: 1rem;
                margin-top: 0px;
              }
              footer .footer-bottom {
                font-size: .875rem;
                background: #000;
                padding: 1rem 0;
                color: hsla(0,0%,100%,.5);
                display: block;
              }
              footer .footer-bottom .footer-bottom-text{
                text-align: center;
                color: hsla(0,0%,100%,.5);
                margin-bottom: 0.25rem;
                display: block;
              }
              footer .footer-bottom .footer-bottom-link {
                text-align: center;
                display: block;
              }
              footer .footer-bottom .footer-bottom-link a{
                color: #fff;
                margin: 0 0.375rem;
              }
              footer .footer-bottom .container {
                padding-right: 20px;
                padding-left: 20px;
              }
              footer .footer-bottom .footer-bottom-divider {
                display: inline;
              }
              footer .footer-top.py-main .container .row .col-lg-4,
              footer .footer-top.py-main .container .row .col-lg-2{
                padding: 0px;
              }
              .social-nav{
                display: block;
                margin: 0 0 0 -0.25rem;
                padding-left: 0;
                list-style: none;
              }
              .social-nav .social-item {
                margin-right: 0.5rem;
                display: inline-block;
              }
              .social-nav .social-item .social-link {
                height: 24px;
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: hsla(0,0%,100%,.5);
              }
              .social-nav .social-item .social-link.footer-youtube-icon{
                width: 30px;
              }
              .social-nav .social-item .social-link.footer-instagram-icon, 
              .social-nav .social-item .social-link.footer-twitter-icon, 
              .social-nav .social-item .social-link.footer-facebook-icon, 
              .social-nav .social-item .social-link.footer-youtube-icon, 
              .social-nav .social-item .social-link.footer-tiktok-icon, 
              .social-nav .social-item .social-link.footer-linkedin-icon{
                fill: currentColor;
              }
              .btn-link {
                padding-left: 0;
                padding-right: 0;
                font-weight: 400;
                color: #ab0204;
                text-decoration: none;
              }
              .post-header h1{
                margin-top: 0;
                margin-bottom: .5rem;
              }
              .post-header .post-attr {
                margin-bottom: 1.5rem;
              }
              .post-header .post-attr .post-attr-divider {
                display: inline-block;
                font-size: .6rem;
                color: #ccc;
                margin: 0 0.5rem;
                position: relative;
                bottom: 0.125rem;
              }
              .post-header .post-category, .post-header .post-date {
                display: inline-block;
                font-size: .8rem;
                color: #999;
              }
              .post-header .post-category {
                text-transform: uppercase;
              }
              .socmed-links{
                width: 100%;
              }
              .socmed-links .icon-place{
                display: inline;
                padding: 0px 3px;
              }
              .socmed-links .icon-place amp-social-share{
                background-color: #333;
              }
              .post-content .pb-4 p {
                margin-top: 0px;
                text-align: center;
              }
              .img-desktop-fluid{
                display: none;
              }
              .img-mobile-fluid{
                display: block;
              }
            }
            @media (min-width: 992px) and (max-width: 1199.98px) {
              .text-center{
                text-align: center:
              }
              .amp-sidebar-mask{
                z-index: 1020;
              }
              .btn-primary{
                padding-top: 0.25rem;
              }
              .mw-md-75 {
                max-width: 75%;
              }
              .post-content p {
                margin-bottom: 1.125rem;
              }
              .post-content * {
                font-size: 1.125rem;
              }
              .post-content .pb-4 p {
                margin-top: 0px;
                text-align: center;
              }
              .mb-4, .my-4 {
                margin-bottom: 1.5rem;
              }
              .d-lg-none {
                display: none;
              }
              .col-lg-2 {
                max-width: 16.66667%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-lg-4 {
                flex: 0 0 33.33333%;
                max-width: 33.33333%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-lg-6 {
                flex: 0 0 50%;
                max-width: 50%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-lg-10 {
                flex: 0 0 83.33333%;
                max-width: 83.33333%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-12 {
                flex: 0 0 100%;
                max-width: 100%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-12.col-lg-2 {
                flex: 0 0 16.66667%;
                max-width: 16.66667%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-12.d-lg-none {
                display: none;
              }
              .row {
                display: flex;
                margin-right: -10px;
                margin-left: -10px;
                width: 100%;
              }
              .container{
                padding-left: 20px;
                padding-right: 20px;
                width: 100%;
                margin-right: auto;
                margin-left: auto;
                max-width: 1140px;
              }
              .py-main{
                padding-top: 5rem;
                padding-bottom: 5rem;
              }
              amp-mega-menu nav{
                height: 60px;
                background: #fff;
              }
              .navbar-wrapper{
                position: fixed;
                left: 0;
                right: 0;
                z-index: 1030;
              }
              #navbarDesktop{
                display: none;
              }
              #navbarMobile{
                display: block;
              }
              #navbarMobile header{
                position: fixed;
                width: 100%;
              }
              #navbarMobile .navbar-nav-main{
                height: 60px;
                max-width: 100%;
              }
              #navbarMobile .navbar-nav-main ul{
                padding: .5rem 4rem;
                max-width: 100%;
              }
              #navbarMobile .navbar-nav-main ul li.navbar-toggler, #navbarMobile .navbar-nav-main ul li.login-toggler{
                float: right;
              }
              #navbarMobile .navbar-nav-main ul li .btn-login{
                text-decoration: none;
                right: 50px;
                padding: 5px 15px;
                margin-top: 10px;
                color: #fff;
                background-color: #ab0204;
                border-color: #ab0204;
                display: inline;
                text-align: center;
                font-weight: 400;
                vertical-align: middle;
                margin-right: 5px;
              }
              #navbarMobile amp-sidebar{
                width: 100%;
                flex-direction: column;
                background-color: #fff;
                max-width: 40vw;
              }
              #navbarMobile amp-sidebar .close-sidebar-left{
                padding: 15px;
                text-align: left;
              }
              #navbarMobile amp-sidebar .close-sidebar-right{
                padding: 15px;
                text-align: right;
              }
              #navbarMobile amp-sidebar ul.sidebar{
                padding: 15px;
                list-style-type: none;
                display: flex;
                flex-direction: column;
              }
              #navbarMobile amp-sidebar ul.sidebar li form .form-control-search{
                position: relative;
                border: none;
                border-bottom: 1px solid #ccc;
                padding: 0.25rem 0;
                margin-bottom: 1rem;
                font-size: 2rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li ul.sub-about{
                list-style-type: none;
              }
              #navbarMobile amp-sidebar ul.sidebar li ul.sub-about li a{
                list-style-type: none;
                font-size: 1.25rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.lang-toggler{
                margin-top: 3rem;
                display: flex;
              }
              #navbarMobile amp-sidebar ul.sidebar li.lang-toggler .mr-2{
                margin-right: 0.5rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.lang-toggler .mr-2.lang-item.active{
                color: #ab0204;
                font-weight: 700;
                margin-right: 0.5rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.primary-link a{
                font-size: .8rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #ab0204;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li span{
                font-size: 2rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #ab0204;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.sub-menu-outline{
                border: 0.5px solid #e5e5e5;
                display: block;
                width: 100%;
                padding-left: 1rem;
                text-align: left;
                position: relative;
                max-width: 90%;
                margin-bottom: 1rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.sub-menu-outline a{
                font-size: 1.25rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li span{
                font-size: 2rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #ab0204;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li a, #navbarMobile amp-sidebar ul.sidebar li span, #navbarMobile amp-sidebar ul.sidebar li .sub-sidebar{
                font-size: 2rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #333;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.glide__slide a{
                font-size: 1rem;
              }
              footer{
                display: block:
              }
              footer .red-ribbon{
                display: flex;
                justify-content: flex-end;
                position: relative;
                margin-top: -16px;
                width: 100%;
              }
              footer .red-ribbon amp-img{
                z-index: 3;
              }
              footer .red-ribbon .ribbon {
                height: 68px;
                position: absolute;
                bottom: 0;
              }
              footer .red-ribbon .ribbon.red {
                z-index: 1;
                left: 0;
                background: #dd1b20;
                height: 54px;
                padding: 7px 0px;
                width: calc(100% - 247px);
              }
              footer .red-ribbon .ribbon.black {
                width: 247px;
                right: 0;
                z-index: 2;
                bottom: 0;
                background: #191919;
              }
              footer .footer-top {
                padding-bottom: 4.5rem;
                background-color: #191919;
                position: relative;
              }
              footer .footer-top .container{
                max-width: 1250px;
              }
              footer .footer-top .container .footer-col.footer-address{
                padding-right: 2.5rem;
              }
              footer .footer-top .container .footer-col h5{
                color: #fff;
                font-size: 1.125rem;
                margin-bottom: 1rem;
              }
              footer .footer-top .container .footer-col.footer-address p{
                line-height: 1.8;
                font-size: .875rem;
                color: hsla(0,0%,100%,.75);
              }
              footer .footer-top hr {
                border-top: 1px solid hsla(0,0%,100%,.1);
                margin-bottom: 1.5rem;
                border: 0;
                margin-top: 1rem;
                box-sizing: content-box;
                height: 0;
                overflow: visible;
              }
              footer .footer-top .container .footer-col .footer-nav{
                list-style-type: none;
                padding: 0;
                margin-bottom: 0;
                margin-top: 0;
              }
              footer .footer-top .container .footer-col .footer-nav .footer-item:first-child .footer-link{
                margin-top: -0.5rem;
                display: inline-block;
                padding: 0.5rem 0;
                color: hsla(0,0%,100%,.5);
              }
              footer .footer-top .container .footer-col .footer-nav .footer-item .footer-link{
                text-decoration: none;
                display: inline-block;
                padding: 0.5rem 0;
                color: hsla(0,0%,100%,.5);
              }
              footer .footer-top .container .footer-col h5{
                color: #fff;
                font-size: 1.125rem;
                margin-bottom: 1rem;
                margin-top: 0px;
              }
              footer .footer-bottom {
                font-size: .875rem;
                background: #000;
                padding: 1rem 0;
                color: hsla(0,0%,100%,.5);
                display: block;
              }
              footer .footer-bottom .footer-bottom-divider {
                display: none;
              }
              footer .footer-bottom .footer-bottom-link {
                text-align: right;
                display: flex;
                justify-content: flex-end;
              }
              footer .footer-bottom .footer-bottom-link a {
                color: hsla(0,0%,100%,.5);
                margin-left: 1rem;
                margin-right: 0;
              }
              .social-nav{
                display: block;
                margin: 0 0 0 -0.25rem;
                padding-left: 0;
                list-style: none;
              }
              .social-nav .social-item {
                margin-right: 0.5rem;
                display: inline-block;
              }
              .social-nav .social-item .social-link {
                height: 20px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: hsla(0,0%,100%,.5);
              }
              .social-nav .social-item .social-link.footer-tiktok-icon {
                width: 16px;
              }
              .social-nav .social-item .social-link.footer-youtube-icon{
                width: 30px;
              }
              .social-nav .social-item .social-link.footer-instagram-icon, 
              .social-nav .social-item .social-link.footer-twitter-icon, 
              .social-nav .social-item .social-link.footer-facebook-icon, 
              .social-nav .social-item .social-link.footer-youtube-icon, 
              .social-nav .social-item .social-link.footer-tiktok-icon, 
              .social-nav .social-item .social-link.footer-linkedin-icon{
                fill: currentColor;
              }
              .btn-link {
                padding-left: 0;
                padding-right: 0;
                font-weight: 400;
                color: #ab0204;
                text-decoration: none;
              }
              .post-header h1{
                margin-top: 0;
                margin-bottom: .5rem;
              }
              .post-header .post-attr {
                margin-bottom: 1.5rem;
              }
              .post-header .post-attr .post-attr-divider {
                display: inline-block;
                font-size: .6rem;
                color: #ccc;
                margin: 0 0.5rem;
                position: relative;
                bottom: 0.125rem;
              }
              .post-header .post-category, .post-header .post-date {
                display: inline-block;
                font-size: .8rem;
                color: #999;
              }
              .post-header .post-category {
                text-transform: uppercase;
              }
              .socmed-links{
                width: 100%;
              }
              .socmed-links .icon-place{
                display: inline;
                padding: 0px 3px;
              }
              .socmed-links .icon-place amp-social-share{
                background-color: #333;
              }
              .post-content .pb-4 p {
                margin-top: 0px;
                text-align: center;
              }
              .img-desktop-fluid{
                display: block;
              }
              .img-mobile-fluid{
                display: none;
              }
            }
            @media (min-width: 1200px) {
              .text-center{
                text-align: center:
              }
              .amp-sidebar-mask{
                z-index: 1020;
              }
              .btn-primary{
                padding-top: 0.25rem;
              }
              .mw-md-75 {
                max-width: 75%;
              }
              .post-content p {
                margin-bottom: 1.125rem;
              }
              .post-content * {
                font-size: 1.125rem;
              }
              .post-content .pb-4 p {
                margin-top: 0px;
                text-align: center;
              }
              .mb-4, .my-4 {
                margin-bottom: 1.5rem;
              }
              .d-lg-none {
                display: none;
              }
              .col-lg-2 {
                max-width: 16.66667%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-lg-4 {
                flex: 0 0 33.33333%;
                max-width: 33.33333%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-lg-6 {
                flex: 0 0 50%;
                max-width: 50%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-lg-10 {
                flex: 0 0 83.33333%;
                max-width: 83.33333%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-12 {
                flex: 0 0 100%;
                max-width: 100%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-12.col-lg-2 {
                max-width: 16.66667%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
              }
              .col-12.d-lg-none {
                display: none;
              }
              .row {
                display: flex;
                margin-right: -10px;
                margin-left: -10px;
                width: 100%;
              }
              .container{
                padding-left: 20px;
                padding-right: 20px;
                width: 100%;
                margin-right: auto;
                margin-left: auto;
                max-width: 1140px;
              }
              .py-main{
                padding-top: 5rem;
                padding-bottom: 5rem;
              }
              amp-mega-menu nav{
                height: 60px;
                background: #fff;
              }
              .navbar-wrapper{
                position: fixed;
                left: 0;
                right: 0;
                z-index: 1030;
              }
              #navbarDesktop{
                display: none;
              }
              #navbarMobile{
                display: block;
              }
              #navbarMobile header{
                position: fixed;
                width: 100%;
              }
              #navbarMobile .navbar-nav-main{
                height: 60px;
                max-width: 100%;
              }
              #navbarMobile .navbar-nav-main ul{
                padding: .5rem 4rem;
                max-width: 100%;
              }
              #navbarMobile .navbar-nav-main ul li.navbar-toggler, #navbarMobile .navbar-nav-main ul li.login-toggler{
                float: right;
              }
              #navbarMobile .navbar-nav-main ul li .btn-login{
                text-decoration: none;
                right: 50px;
                padding: 5px 15px;
                margin-top: 10px;
                color: #fff;
                background-color: #ab0204;
                border-color: #ab0204;
                display: inline;
                text-align: center;
                font-weight: 400;
                vertical-align: middle;
                margin-right: 5px;
              }
              #navbarMobile amp-sidebar{
                width: 100%;
                flex-direction: column;
                background-color: #fff;
                max-width: 40vw;
              }
              #navbarMobile amp-sidebar .close-sidebar-left{
                padding: 15px;
                text-align: left;
              }
              #navbarMobile amp-sidebar .close-sidebar-right{
                padding: 15px;
                text-align: right;
              }
              #navbarMobile amp-sidebar ul.sidebar{
                padding: 15px;
                list-style-type: none;
                display: flex;
                flex-direction: column;
              }
              #navbarMobile amp-sidebar ul.sidebar li form .form-control-search{
                position: relative;
                border: none;
                border-bottom: 1px solid #ccc;
                padding: 0.25rem 0;
                margin-bottom: 1rem;
                font-size: 2rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li ul.sub-about{
                list-style-type: none;
              }
              #navbarMobile amp-sidebar ul.sidebar li ul.sub-about li a{
                list-style-type: none;
                font-size: 1.25rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.lang-toggler{
                margin-top: 3rem;
                display: flex;
              }
              #navbarMobile amp-sidebar ul.sidebar li.lang-toggler .mr-2{
                margin-right: 0.5rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.lang-toggler .mr-2.lang-item.active{
                color: #ab0204;
                font-weight: 700;
                margin-right: 0.5rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.primary-link a{
                font-size: .8rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #ab0204;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li span{
                font-size: 2rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #ab0204;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.sub-menu-outline{
                border: 0.5px solid #e5e5e5;
                display: block;
                width: 100%;
                padding-left: 1rem;
                text-align: left;
                position: relative;
                max-width: 90%;
                margin-bottom: 1rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.sub-menu-outline a{
                font-size: 1.25rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li span{
                font-size: 2rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #ab0204;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li a, #navbarMobile amp-sidebar ul.sidebar li span, #navbarMobile amp-sidebar ul.sidebar li .sub-sidebar{
                font-size: 2rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                color: #333;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                padding: .5rem 0rem;
              }
              #navbarMobile amp-sidebar ul.sidebar li.glide__slide a{
                font-size: 1rem;
              }
              footer{
                display: block:
              }
              footer .red-ribbon{
                display: flex;
                justify-content: flex-end;
                position: relative;
                margin-top: -16px;
                width: 100%;
              }
              footer .red-ribbon amp-img{
                z-index: 3;
              }
              footer .red-ribbon .ribbon {
                height: 68px;
                position: absolute;
                bottom: 0;
              }
              footer .red-ribbon .ribbon.red {
                z-index: 1;
                left: 0;
                background: #dd1b20;
                height: 54px;
                padding: 7px 0px;
                width: calc(100% - 247px);
              }
              footer .red-ribbon .ribbon.black {
                width: 247px;
                right: 0;
                z-index: 2;
                bottom: 0;
                background: #191919;
              }
              footer .footer-top {
                padding-bottom: 4.5rem;
                background-color: #191919;
                position: relative;
              }
              footer .footer-top .container{
                max-width: 1250px;
              }
              footer .footer-top .container .footer-col.footer-address{
                padding-right: 2.5rem;
              }
              footer .footer-top .container .footer-col h5{
                color: #fff;
                font-size: 1.125rem;
                margin-bottom: 1rem;
              }
              footer .footer-top .container .footer-col.footer-address p{
                line-height: 1.8;
                font-size: .875rem;
                color: hsla(0,0%,100%,.75);
              }
              footer .footer-top hr {
                border-top: 1px solid hsla(0,0%,100%,.1);
                margin-bottom: 1.5rem;
                border: 0;
                margin-top: 1rem;
                box-sizing: content-box;
                height: 0;
                overflow: visible;
              }
              footer .footer-top .container .footer-col .footer-nav{
                list-style-type: none;
                padding: 0;
                margin-bottom: 0;
                margin-top: 0;
              }
              footer .footer-top .container .footer-col .footer-nav .footer-item:first-child .footer-link{
                margin-top: -0.5rem;
                display: inline-block;
                padding: 0.5rem 0;
                color: hsla(0,0%,100%,.5);
              }
              footer .footer-top .container .footer-col .footer-nav .footer-item .footer-link{
                text-decoration: none;
                display: inline-block;
                padding: 0.5rem 0;
                color: hsla(0,0%,100%,.5);
              }
              footer .footer-top .container .footer-col h5{
                color: #fff;
                font-size: 1.125rem;
                margin-bottom: 1rem;
                margin-top: 0px;
              }
              footer .footer-bottom {
                font-size: .875rem;
                background: #000;
                padding: 1rem 0;
                color: hsla(0,0%,100%,.5);
                display: block;
              }
              footer .footer-bottom .footer-bottom-divider {
                display: none;
              }
              footer .footer-bottom .footer-bottom-link {
                text-align: right;
                display: flex;
                justify-content: flex-end;
              }
              footer .footer-bottom .footer-bottom-link a {
                color: hsla(0,0%,100%,.5);
                margin-left: 1rem;
                margin-right: 0;
              }
              .social-nav{
                display: block;
                margin: 0 0 0 -0.25rem;
                padding-left: 0;
                list-style: none;
              }
              .social-nav .social-item {
                margin-right: 0.5rem;
                display: inline-block;
              }
              .social-nav .social-item .social-link {
                height: 20px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: hsla(0,0%,100%,.5);
              }
              .social-nav .social-item .social-link.footer-tiktok-icon {
                width: 20px;
              }
              .social-nav .social-item .social-link.footer-youtube-icon{
                width: 30px;
              }
              .social-nav .social-item .social-link.footer-instagram-icon, 
              .social-nav .social-item .social-link.footer-twitter-icon, 
              .social-nav .social-item .social-link.footer-facebook-icon, 
              .social-nav .social-item .social-link.footer-youtube-icon, 
              .social-nav .social-item .social-link.footer-tiktok-icon, 
              .social-nav .social-item .social-link.footer-linkedin-icon{
                fill: currentColor;
              }
              .btn-link {
                padding-left: 0;
                padding-right: 0;
                font-weight: 400;
                color: #ab0204;
                text-decoration: none;
              }
              .post-header h1{
                margin-top: 0;
                margin-bottom: .5rem;
              }
              .post-header .post-attr {
                margin-bottom: 1.5rem;
              }
              .post-header .post-attr .post-attr-divider {
                display: inline-block;
                font-size: .6rem;
                color: #ccc;
                margin: 0 0.5rem;
                position: relative;
                bottom: 0.125rem;
              }
              .post-header .post-category, .post-header .post-date {
                display: inline-block;
                font-size: .8rem;
                color: #999;
              }
              .post-header .post-category {
                text-transform: uppercase;
              }
              .socmed-links{
                width: 100%;
              }
              .socmed-links .icon-place{
                display: inline;
                padding: 0px 3px;
              }
              .socmed-links .icon-place amp-social-share{
                background-color: #333;
              }
              .img-desktop-fluid{
                display: block;
              }
              .img-mobile-fluid{
                display: none;
              }
              amp-img.img-content img{
                object-fit: contain;
              }
            }
          `}
        </style>
        <script async src="https://cdn.ampproject.org/v0.js"></script>
        <script async custom-element="amp-form" src="https://cdn.ampproject.org/v0/amp-form-0.1.js"></script>
        <script async custom-element="amp-mega-menu" src="https://cdn.ampproject.org/v0/amp-mega-menu-0.1.js"></script>
        <script async custom-element="amp-sidebar" src="https://cdn.ampproject.org/v0/amp-sidebar-0.1.js"></script>
        <script async custom-element="amp-social-share" src="https://cdn.ampproject.org/v0/amp-social-share-0.1.js"></script>
      </Helmet>
      <SEO
        title={seo.title}
        description={seo.opengraphDescription}
        fbTitle={seo.opengraphTitle || seo.title}
        fbDescription={seo.opengraphDescription}
        twitterTitle={seo.twitterTitle || seo.title}
        twitterDescription={seo.twitterDescription || seo.opengraphDescription}
        img={featuredImage}
        fbImg={featuredImage}
        twitterImg={featuredImage}
        url={redirectAmpHtml.replace('/news/amp/', '/news/')}
        type="article"
        schemaMarkup={schema}
      />

      <Section className="post-details pt-main-sm">
        <Container className="mt-40">
          <div class="row">
            <div className="col-lg-2">
              <Button variant="link" link={props.pathContext.langKey === "ID" ? "/id/news" : "/news"}>
                {props.pathContext.langKey === "ID"
                  ? "Lihat Semua Berita"
                  : "See All News"}
              </Button>
            </div>
            <div className="col-lg-10">
              <PostHeader
                title={
                  newsItems &&
                  newsItems?.title
                    .replace(/(<([^>]+)>)/gi, "")
                    .replace(/&#8211;/gi, "-")
                    .replace(/&#038;/gi, "&")
                    .replace(/&#8216;/gi, "'")
                    .replace(/&#8221;/gi, '"')
                }
                category={newsItem?.category}
                date={newsItems && moment(newsItems.date).format("LL")}
                imgAlt={
                  newsItems && newsItems?.featuredImage
                    ? newsItems?.title
                    : null
                }
              />

              <SocmedLinks
                facebookLink={props.location}
                twitterLink={props.location}
                linkedinLink={props.location}
                copyLink={redirectAmpHtml}
                className="mb-4"
                isAmp={true}
                location={props.location}
              />

              <PostContent className="mw-md-75">
                {newsItems &&
                  newsItems?.blocks?.map((item, i) => {
                    switch (item.name) {
                      case "core/heading":
                        return <Headings key={`heading-${i}`} data={item} />
                      case "core/paragraph":
                        return (
                          item?.attributes?.content && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.attributes?.content,
                              }}
                            />
                          )
                        )
                      case "core/image":
                        return (
                          <div key={`core-image-${i}`} className="pb-4">
                            <amp-img
                              alt="img-bsd-train-station"
                              src={item?.attributes?.url}
                              class="img-content"
                              width="150"
                              height="100"
                              layout="responsive"
                              heights="(min-width: 300px) 80%"
                              sizes="(max-width: 400px) 90vw, (max-width: 960px) 100%, 720px"
                            ></amp-img>
                            <p key={`caption-${i}`} className="text-center m-0" dangerouslySetInnerHTML={{ __html: item?.attributes?.caption}} style={{ fontSize: "16px", marginTop: "-12px" }}/>
                          </div>
                        )
                      case "core/cover":
                        return (
                          <div key={`featured-${i}`} className="pb-4">
                            <CoverFeatured
                              img={
                                item?.attributes?.url ||
                                staticData?.featured?.childImageSharp?.fluid
                              }
                              title=""
                              text=""
                            />
                          </div>
                        )
                      case "core/list":
                        return (
                          <div className="heading heading-basic" style={{maxWidth:"100%",textAlign:"justify"}}>
                            {item.attributes.start !== null && (
                              <ol start={item.attributes.start} style={{paddingLeft:"20px", color:"#333"}}>
                                <ReactMarkdown
                                  source={item.attributes.values}
                                  escapeHtml={false}
                                />
                              </ol>
                            )}
                            {item.attributes.start === null && (
                              <ul style={{listStyleType:"disk", paddingLeft:"20px", color:"#333"}}>
                                <ReactMarkdown
                                  source={item.attributes.values}
                                  escapeHtml={false}
                                />
                              </ul>
                            )}
                          </div>
                        )
                      default:
                        return <div key={`empty-${i}`}></div>
                    }
                  })}
              </PostContent>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default News
